@import '~@blueprintjs/core/lib/css/blueprint.css';
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/popover2/lib/css/blueprint-popover2.css";
@import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
@import "@blueprintjs/datetime2/lib/css/blueprint-datetime2.css";
@import "~@blueprintjs/select/lib/css/blueprint-select.css";
@import '~@blueprintjs/core/lib/scss/variables';

body {
  margin: 0;
  overscroll-behavior-y: none;
  background-color: $pt-app-background-color;
}
